import React from "react";
import Layout from "../components/Layout";
import { BlueCurve1, GrayCurve1 } from "../components/Curves";
import {
  ContactFormSection,
  ContactInfoSection,
  ContactWelcomeSection,
} from "../sections";

const ContactPage = () => {
  return (
    <Layout title="ChatIQ | Contact" darkFooter>
      <ContactWelcomeSection />
      <GrayCurve1 />
      <ContactInfoSection />
      <ContactFormSection />
      <BlueCurve1 />
    </Layout>
  );
};

export default ContactPage;
